import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Activities from "./components/activities";
// import Bells from "./components/bellsOfRevival";
import BibleStudy from "./components/bibleStudy";
import ChildrensChurch from "./components/childrensChurch";
import Download from "./components/download";
import Footer from "./components/footer";
import SideDrawer from "./components/sideDrawer";
import Home from "./components/landingPage";
import Watch from "./components/watch/watch";
import Giving from "./components/giving";
import SundayService from "./components/sundayService";
import Legal from "./components/legal";
import NotFound from "./components/notFound";
import ThankYou from "./components/thankYou";
//import ProtectedRoute from "./components/common/protectedRoute";
import Backdrop from "./components/backdrop";
import "react-toastify/dist/ReactToastify.css";
import "./scss/App.scss";
import "antd/dist/antd.css";
import PrivacyPolicy from "./components/privacyPolicy";
import Visitor from "./components/visitor";
import VisitorThanks from "./components/comeAgain";
import NavBar from "./components/navBar";

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };

  toggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  handleBackdropClick = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    //const user = this.state;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop backdropHandler={this.handleBackdropClick} />;
    }
    return (
      <React.Fragment>
        <ToastContainer />
        {window.location.pathname !== "/app" ? (
          <NavBar drawerClickHandler={this.toggleClickHandler} />
        ) : null}

        {/* <SideDrawer
          //user={user}
          show={this.state.sideDrawerOpen}
          backdropHandler={this.handleBackdropClick}
        /> */}
        {backdrop}
        <main id="container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/activities" component={Home} />
            <Route path="/app" component={Home} />
            {/* <Route
              path="/bells-of-revival"
              component={() => (
                <Bells drawerClickHandler={this.toggleClickHandler} />
              )}
            /> */}
            <Route path="/bible_study" component={Home} />
            {/* <Route path="/care" component={Care} /> */}
            <Route path="/come-again" component={Home} />
            <Route path="/giving" component={Home} />
            <Route path="/legal" component={Home} />
            <Route path="/privacyPolicy" component={Home} />
            <Route path="/new-visitor" component={Home} />
            <Route path="/sunday-service" component={Home} />
            <Route path="/thankyou" component={Home} />
            <Route path="/victorious_kids" component={Home} />
            <Route path="/watch" component={Home} />
            <Route path="/not_found" component={Home} />
            <Redirect to="/not_found" />
          </Switch>
        </main>
        {window.location.pathname !== "/app" ? <Footer /> : null}
      </React.Fragment>
    );
  }
}

export default withRouter(App);
