import React, { Component } from "react";
//import { Link } from "react-router-dom";
import video from "../Website/giving.mp4";
import img from "../Website/cashapp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingHeart,
  faMoneyCheckAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faApplePay, faPaypal } from "@fortawesome/free-brands-svg-icons";

class Giving extends Component {
  render() {
    return (
      <div className="give-container">
        <div className="give overlay">
          <div className="layer">
            <div className="content">
              <h1>Giving</h1>
            </div>
          </div>
        </div>
        <div className="content-1">
          <div className="banner container">
            <h1 className="site-header">The Word of God says...</h1>
            <p className="site-text">
              {" "}
              "
              <span className="verse">
                Give, and it will be given to you. They will pour into your lap
                a good measure - pressed down, shaken together, and running
                over. For by your standard of measure it will be measured to you
                in return.
              </span>
              " Luke 6: 38
            </p>
            <div className="donate">
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_blank"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="MPK782BXKSR78"
                />

                <button type="submit" className="submit">
                  Give <FontAwesomeIcon icon={faPaypal} id="paypal" />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="content-2">
          <div className="context container">
            <p className="site-text">
              At Fountain Church, we teach to give according to the scriptures.
              Giving is not only in monetary form. We teach and encourage
              believers that they can give anything that can contribute to the
              advancement of the Kingdom of God (money, material things as well
              as time). Tithing (giving 10% of income) is a biblical and
              practical principle. At Fountain Church, we practice and encourage
              this lifestyle and have come to understand that God is faithful to
              do what He has said He will do in His word. We request tax payers
              to fill and sign a Gift Aid Declaration or the Gift Aid section of
              the offering envelope in order to allow the church to claim a
              large percentage of their gift back from the government (HMRC).
            </p>
            <p className="site-text">
              Fountain church is subject to scrutiny by the Charity Commission
              and has the obligation to produce Annual Audited Results for
              reasons of stewardship, proper management and transparency.
              Details of our financial performance can be found on the charity
              commission website.
            </p>
          </div>
        </div>
        <div className="content-3 container">
          <div className="ways-to-give">
            <div className="first">
              <div className="place">
                <FontAwesomeIcon icon={faHandHoldingHeart} id="heart" />
                <h4>CASH | CHEQUE</h4>
                <p className="site-text">Into the offering baskets</p>
              </div>
              <div className="place">
                <FontAwesomeIcon icon={faApplePay} id="apple" />
                <h4>CARD | MOBILE PAY</h4>
                <p className="site-text">On our terminals in the church</p>
              </div>
            </div>
            <div className="second">
              <div className="place">
                <img src={img} alt="cashapp" />
              </div>
            </div>
            <div className="third">
              <div className="place">
                <FontAwesomeIcon icon={faPaypal} id="pay" />
                <h4>PAYPAL</h4>
                <p className="site-text">give@fountainchurch.net</p>
              </div>
              <div className="place">
                <FontAwesomeIcon icon={faMoneyCheckAlt} id="money" />
                <h4>BANK TRANSFER | STANDING ORDER</h4>
                <p className="site-text">
                  Fountain Church
                  <br />
                  Account Number: 72079771
                  <br />
                  Sort Code: 40-08-32
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-4">
          <video src={video} type="video/mp4" controls>
            Your browser does not support this video.
          </video>
        </div>
      </div>
    );
  }
}

export default Giving;

// <div className="btn">
//   <Link className="link" to="#">
//     Give
//   </Link>
// </div>

//<form <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" /><img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" /></form>
