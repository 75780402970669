import React, { Component } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";

class VisitorThanks extends Component {
  render() {
    return (
      <div
        className="visit"
        style={{
          // display: "flex",
          // justifyContent: "center",
          marginTop: "10%",
          marginBottom: "10%",
          // padding: "0px",
        }}
      >
        <div className="wash"></div>
        <Grid>
          <Card
            style={{
              zIndex: 1,
              position: "relative",
              maxWidth: "80%",
              minHeight: "40vh",
              display: "flex",
              alignItems: "center",

              border: "none",
              textAlign: "center",
              justifyContent: "center",
              padding: "20px 5px",
              margin: "0 auto",
              // boxShadow: "0 0 5px #175873",
            }}
          >
            <CardContent>
              <Typography
                variant="h2"
                className="site-header"
                style={{ color: "green", zIndex: 1 }}
              >
                Thank you for coming to our service.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default VisitorThanks;
