import React, { Component } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { Pagination } from "antd";
import { Grid, Button, Modal, Box } from "@mui/material";

const YOUTUBE_URI = "https://www.googleapis.com/youtube/v3/playlistItems";

export default class Sermons extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: [],
      watch: {},
      currentPage: 1,
      pageSize: 9,
      error: false,
      open: false,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  async componentDidMount() {
    await fetch(
      `${YOUTUBE_URI}?part=snippet&playlistId=PLeNI85sOHZ-jN1K6tNPr2PbJ5vZfxFAJD&maxResults=50&key=${process.env.REACT_APP_API_KEY}`
    )
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          data: [...data.items],
          isLoading: false,
        })
      );
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error">
          <p className="site-text">
            Please visit our{" "}
            <a
              href="https://www.youtube.com/fountainchurchkent"
              rel="noopener noreferrer"
              target="_blank"
            >
              YouTube
            </a>{" "}
            for all Sermons.
          </p>
        </div>
      );
    } else if (this.state.isLoading) {
      return <h1 className="loading">LOADING...</h1>;
    }

    const { currentPage, pageSize, data, watch } = this.state;

    const indexOfLastVideo = currentPage * pageSize;
    const indexOfFirstVideo = indexOfLastVideo - pageSize;
    const currentVideos = data.slice(indexOfFirstVideo, indexOfLastVideo);

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // width: 400,
      bgcolor: "#18181c",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
    console.log(this.state);

    return (
      <div className="sermon-playlist">
        <Grid className="sermon-card" container>
          {currentVideos.map(
            (video, index) =>
              video.snippet.title !== "Private video" && (
                <Card className="main-card" key={video.id}>
                  <Button
                    className="link"
                    onClick={() =>
                      this.setState({
                        watch: video,
                        open: true,
                      })
                    }
                  >
                    <Card.Img
                      className="images"
                      src={
                        video.snippet.thumbnails.maxres?.url ??
                        video.snippet.thumbnails.high?.url
                      }
                      alt="video title"
                    />
                    <Card.Body className="card-body">
                      <Card.Title className="title">
                        {video.snippet.title}
                      </Card.Title>
                      <Card.Subtitle className="subtitle">
                        {video.snippet.channelTitle}
                      </Card.Subtitle>
                    </Card.Body>
                  </Button>
                </Card>
              )
          )}
        </Grid>
        <div className="pagination">
          <Pagination
            size="small"
            pageSize={pageSize}
            total={data.length}
            defaultCurrent={currentPage}
            onChange={paginate}
          />
        </div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className="movie"
            style={{
              maxHeight: "95%",
              minWidth: "90vw",
              overflowY: "scroll",
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              id="closeModal"
              onClick={this.handleClose}
            />
            <div className="contain">
              <div
                className="videoWrapper no-over rel-pos full-width text-center"
                style={{ height: "0 !important" }}
              >
                <iframe
                  title="title"
                  frameBorder="0"
                  src={`https://www.youtube.com/embed/${watch.snippet?.resourceId?.videoId}`}
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  controls
                  width="100%"
                  allowFullScreen
                />
              </div>
              <div className="feature-text">
                <h2
                  className="site-header"
                  id="modal-modal-title"
                  style={{ color: "#ffffff" }}
                >
                  {watch.snippet?.title}{" "}
                  <span style={{ color: "#808080", fontSize: "16px" }}>
                    {moment(watch.snippet?.publishedAt).format("DD-MM-YYYY")}
                  </span>
                </h2>

                <p className="site-text" style={{ color: "#808080" }}>
                  {watch.snippet?.description}
                </p>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}
