import React, { Component } from "react";

class SundayService extends Component {
  render() {
    return (
      <div
        className="container-fluid activities"
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <div
          className="image-section__textcenter"
          style={{ marginTop: "40px", marginBottom: "60px" }}
        >
          <h3
            className="service-text"
            style={{ color: "white", marginBottom: "20px" }}
          >
            WE LOOK FORWARD TO SEEING YOU
          </h3>
          <h2
            className="service-middle"
            style={{ color: "white", marginBottom: "20px" }}
          >
            SOON IN ONE OF OUR SERVICES.
          </h2>
          <h3 className="service-text" style={{ color: "white" }}>
            DOWNLOAD OUR APP TO STAY IN TOUCH
          </h3>
        </div>
        {/* </div> */}
      </div>
      // <div className="home">
      //   <div className="container-fluid item-section section-top">
      //     <div className="row" style={{ width: "100vw" }}>
      //       <div className="image-section__textcenter">
      //
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default SundayService;
