import React, { Component } from "react";
import axios from "axios";
import { apiUrl } from "../../config.json";
import { Card } from "react-bootstrap";
import { Pagination } from "antd";

const apiEndpoint = apiUrl + "/victoriouskids";

export default class VK extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      videos: [],
      currentPage: 1,
      pageSize: 6,
      error: false,
    };
  }
  async componentDidMount() {
    await axios
      .get(apiEndpoint)
      .then((res) => {
        let sorted = res.data.sort(function (a, b) {
          return a.videoDate === b.videoDate
            ? 0
            : a.videoDate < b.videoDate
            ? 1
            : -1;
        });
        this.setState({
          videos: [...sorted],
          isLoading: false,
        });
      })
      .catch((err) => {
        if (err.response.status !== 200) {
          this.setState({ error: true });
        }
      });
  }
  render() {
    if (this.state.error) {
      return (
        <div className="error">
          <p className="site-text">
            Please visit our{" "}
            <a
              href="https://www.youtube.com/fountainchurchkent"
              rel="noopener noreferrer"
              target="_blank"
            >
              YouTube
            </a>{" "}
            for all Victorious Kids episodes.
          </p>
        </div>
      );
    } else if (this.state.isLoading) {
      return <h1 className="loading">LOADING...</h1>;
    }

    const { currentPage, pageSize, videos } = this.state;

    const indexOfLastVideo = currentPage * pageSize;
    const indexOfFirstVideo = indexOfLastVideo - pageSize;
    const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });

    // const nextPage = () => this.setState({ currentPage: currentPage + 1 });

    // const prevPage = () => this.setState({ currentPage: currentPage - 1 });

    return (
      <div className="vk-playlist">
        <div className="container">
          <div className="vk-card">
            {currentVideos.map((video, index) => (
              <Card className="main-card" key={video.id}>
                <a
                  className="link"
                  href={video.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Card.Img
                    className="images"
                    src={video.videoThumbnail}
                    alt="video title"
                  />
                  <Card.Body className="card-body">
                    <Card.Title className="title">{video.title}</Card.Title>
                    <Card.Subtitle className="subtitle">
                      {video.author}
                    </Card.Subtitle>
                  </Card.Body>
                </a>
              </Card>
            ))}
          </div>
          <div className="pagination">
            <Pagination
              size="small"
              pageSize={pageSize}
              total={videos.length}
              defaultCurrent={currentPage}
              onChange={paginate}
            />
          </div>
        </div>
      </div>
    );
  }
}
