import React, { Component } from "react";

class BibleStudy extends Component {
  render() {
    return (
      <div className="bible-study container">
        <div className="content-bible-1 overlay">
          <div className="layer">
            <div className="content"></div>
          </div>
        </div>
        <div className="content-bible-2">
          <h1 className="site-header">Bible Study</h1>
          <p className="site-text">
            At Fountain Church, one of our priorities is to learn and understand
            the scriptures so that we get edified and build our knowledge of God
            and ourselves. The bible says that
            <span className="verse">
              {" "}
              <strong>
                “My people are destroyed for lack of knowledge” Hosea 4:6.
              </strong>
            </span>{" "}
            We welcome you to join us in studying scripture for it God’s will
            that we know and understand His Word.The bible study is open to all
            who choose to come and is on every Wednesday at 6:30pm-7:30pm.
          </p>
        </div>
        <div className="content-bible-3">
          <h3>
            <span className="verse">
              All Scripture is breathed out by God and profitable for teaching,
              for reproof, for correction, and for training in righteousness,
              that the man of God may be competent, equipped for every good
              work.
            </span>
            <br></br>2 Timothy 3:16-17
          </h3>
        </div>
      </div>
    );
  }
}

export default BibleStudy;
