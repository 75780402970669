import React from "react";
import { Link } from "react-router-dom";
import DrawerToggle from "./drawerToggle";
import img from "../image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";

const NavBar = ({ drawerClickHandler }) => {
  return (
    <header className="navbar" id="navBar">
      <div className="container">
        <nav className="navbar__navigation">
          <div className="navbar__logo">
            <Link className="logo" to="/">
              <img style={{ height: "62px" }} src={img} alt="logo" />
            </Link>
          </div>

          <div className="navbar_navigation-items">
            {/* <Link className="items" to="/watch">
              Watch
            </Link>
            <Link className="items" to="/bells-of-revival">
              Bells Of Revival
            </Link>
             */}

            {/* <Link className="items" to="/giving">
              Give
            </Link> */}
            <a
              className="items"
              href="/#IMPACT22"
              style={{ marginRight: "10px" }}
            >
              #KINGDOMIMPACT
            </a>

            {/* <a
              className="items"
              href="/#activities"
              style={{ marginRight: "10px" }}
            >
              Activities
            </a> */}
          </div>
          <div className="next">
            <div className="donate">
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_blank"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="MPK782BXKSR78"
                />
                {/* <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              /> */}
                <button type="submit" className="submit">
                  Give <FontAwesomeIcon icon={faPaypal} id="paypal" />
                </button>
              </form>
            </div>

            <div className="drawer-toggle">
              {/* <DrawerToggle click={drawerClickHandler} /> */}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
