import React, { Component } from "react";
import img from "../Website/Untitled design (1).png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { faAt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faPaypal,
} from "@fortawesome/free-brands-svg-icons";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-container">
        <div className="footer container">
          <div className="donate">
            <div className="appPromo">
              <img className="app" src={img} alt="Fountain Church App" />
              <div className="stores">
                <p className="site-text">Get the Fountain Church App Today!</p>
                <div className="appLinks">
                  <a
                    href="https://apps.apple.com/us/app/fountain-church-ashford-kent/id1480756323?itsct=apps_box&amp;itscg=30200"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="appStore"
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?&amp;releaseDate=1615507200&h=6e685dd6152cf79d6c8ed9c43c48f0cd"
                      alt="Download on the App Store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.finalchurch.android&hl=en&gl=GB&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="playStore"
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_blank"
            >
              <input
                type="hidden"
                name="hosted_button_id"
                value="MPK782BXKSR78"
              />
              {/* <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              /> */}
              <button type="submit" className="submit">
                Give <FontAwesomeIcon icon={faPaypal} id="paypal" />
              </button>
            </form>
            <div className="socials">
              <a
                href="https://www.facebook.com/fountainchurchkent/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} id="facebook" />
              </a>
              <a
                href="https://www.instagram.com/fountainchurchkent/?hl=en"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} id="instagram" />
              </a>
              <a
                href="https://www.youtube.com/fountainchurchkent"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faYoutube} id="youtube" />
              </a>
            </div>
          </div>
          <div className="mission">
            <h3>Our Vision</h3>
            <p>
              Proclaiming the gospel of Jesus Christ and promoting spiritual
              growth among Christians in UK and beyond. Teaching the Christian
              faith in UK and beyond to women and men of all ages by encouraging
              them to model a Christ like lifestyle and transformational
              development as presented in the Bible.
            </p>
          </div>
          <div className="mission">
            <h3>Contact</h3>
            <p>
              <FontAwesomeIcon icon={faAt} id="email" />
              info@fountainchurch.net
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelopeOpen} id="post" />
              Fountain Church<br></br>5 & 6 Monpellier Business Park<br></br>
              Dencora Way Ashford Kent<br></br>
              TN23 8LR
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} id="phone" />
              +44 1233 877377
            </p>
          </div>
        </div>
        <p className="footer-p">
          {new Date().getFullYear()} © Bells of Revival Ministries. All Rights
          Reserved
        </p>
      </footer>
    );
  }
}

export default Footer;
