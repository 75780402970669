import React, { Component } from "react";
import img from "../Website/Under_Construction_-_Webpage_or_Project_Under_Construction.jpg";
import service from "../Website/PP.JPG";
import SundayService from "./sundayService";
import Activities from "./activities";

class Home extends Component {
  render() {
    return (
      <div
        className="home"
        style={{
          height: "61vh",
        }}
      >
        <div className="wash"></div>
        <div
          style={{
            display: "flex",
            zIndex: "1",
            color: "white",
            height: "100%",
            width: "100vw",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1
            update
            className="main-header"
            style={{
              color: "white",
              marginBottom: "20px",
            }}
          >
            <span style={{ color: "#ffcc00" }}>UNDER CONSTRUCTION </span>
            <br />
            WE WILL BE BACK SOON
          </h1>
        </div>
        {/* <img style={{ width: "100%" }} src={img} alt="We are fountain church" /> */}
        {/* <div className="top-banner"> */}
        {/* <div className="top-banner-container">
            <div className="contain">
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "95%" }}
                  src={img}
                  alt="We are fountain church"
                />
              </div>
            </div>
          </div> */}
        {/* <div className="container-fluid item-section section-top"> */}
        {/* <div className="row">
          <div className="col-lg-5 offset-lg-1">
            <div className="image-section__textleft">
              <h2
                update
                className="main-header"
                style={{
                  color: "white",
                  marginBottom: "20px",
                }}
              >
                WE WILL BE BACK SOON
              </h2>
            </div>
          </div>
          <div className="col-lg-6 col-tight">
            <img src={service} alt="Service" />
          </div>
        </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <Activities />
        <SundayService /> */}

        {/* <div className="container-fluid">
          <div className="wash"></div>
          <div className="row">
            <div className="col-lg-6 col-tight">
              <iframe
                title="G-maps"
                className="map-content map-content-one"
                frameBorder="0"
                style={{ width: "100%", height: "50vh" }}
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBTWoxS27J44zJ_Gd2iUMULQENa4c0XMUQ&q=Fountain+Church,Ashford+UK"
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="image-section__textleft">
                <h2 className="site-header" style={{ color: "#808080" }}>
                  {" "}
                  COME VISIT US
                </h2>
                <p className="site-text" style={{ color: "white" }}>
                  Dencora Way, 1 Montpelier Business Park,
                  <br />
                  Ashford, Kent TN23 4FG
                </p>
                <p className="site-text" style={{ color: "white" }}>
                  <strong>Parking: </strong>Our ushers will guide you from the
                  roundabout to the parking area in front of our building.
                </p>
                <p className="site-text" style={{ color: "white" }}>
                  <strong>Email:</strong> info@fountainchurch.net
                </p>
                <p className="site-text" style={{ color: "white" }}>
                  <strong>Tel:</strong> 01233 877377
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Home;
