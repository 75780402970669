import React, { Component } from "react";
import Sermons from "./sermons";
import Recaps from "./recap";
import Get2Know from "./get2Know";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faVideo, faInfo } from "@fortawesome/free-solid-svg-icons";

class Watch extends Component {
  state = {
    isSermonsActive: 1,
  };

  changeState() {
    this.setState({ isSermonsActive: 2 });
  }

  revertState() {
    this.setState({ isSermonsActive: 1 });
  }

  nextState() {
    this.setState({ isSermonsActive: 3 });
  }

  render() {
    return (
      <>
        <div className="watch">
          <div className="wash"></div>
          <div className="watch-title">
            <h1 className="site-header" style={{ color: "#ffffff", zIndex: 1 }}>
              Watch
            </h1>
          </div>
          <div className="main-contain">
            <div
              className="videoWrapper no-over rel-pos full-width text-center"
              style={{ height: "0 !important" }}
            >
              <iframe
                title="title"
                frameBorder="0"
                src="https://www.youtube.com/embed/0cKHL4ViEZA"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                controls
                width="100%"
                allowFullScreen
              />
            </div>
          </div>
          <div className="title-container">
            <button
              disabled={this.state.isSermonsActive === 2}
              className="title-button2"
              onClick={this.changeState.bind(this)}
            >
              <FontAwesomeIcon icon={faRedo} id="title-icon" />
              Recaps
            </button>
            <button
              disabled={this.state.isSermonsActive === 1}
              className="title-button1"
              onClick={this.revertState.bind(this)}
            >
              <FontAwesomeIcon icon={faVideo} id="title-icon" /> Sermons
            </button>
            <button
              disabled={this.state.isSermonsActive === 3}
              className="title-button3"
              onClick={this.nextState.bind(this)}
            >
              <FontAwesomeIcon icon={faInfo} id="title-icon" />
              Get To Know
            </button>
          </div>
          <div className="display">
            {this.state.isSermonsActive === 1 && (
              <div className="display">
                <h1 className="loading">Sermons</h1>
                <Sermons />
              </div>
            )}
            {this.state.isSermonsActive === 2 && (
              <div className="display">
                <h1 className="loading">Recaps</h1>
                <Recaps />
              </div>
            )}
            {this.state.isSermonsActive === 3 && (
              <div className="display">
                <h1 className="loading">Get To Know</h1>
                <Get2Know />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Watch;
