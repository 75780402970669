import React, { Component } from "react";
import { Grid, Box, Popover } from "@mui/material";
import gif from "../Website/impactgif.gif";
import img1 from "../Website/sunday.png";
import img2 from "../Website/Bible.png";
import img3 from "../Website/restore.png";
import img4 from "../Website/MP.png";
import img5 from "../Website/cxnnected.png";
import img6 from "../Website/Belles.png";
import img7 from "../Website/bridge.png";
import img8 from "../Website/outreach.png";
import img9 from "../Website/vk.png";
import img10 from "../Website/crossover.jpeg";
import img11 from "../Website/firstSunday.jpeg";

class Activities extends Component {
  state = { anchorEl: null, anchorElNew: null };

  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNewPopoverOpen = (event) => {
    this.setState({ anchorElNew: event.currentTarget });
  };

  handleNewPopoverClose = () => {
    this.setState({ anchorElNew: null });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const newOpen = Boolean(this.state.anchorElNew);

    return (
      <div className="activities">
        <div
          className="container-fluid"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "100px",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="IMPACT22"
        >
          <h2
            className="site-header"
            style={{ color: "white", marginBottom: "30px" }}
          >
            #IMPACT22
          </h2>
          <div className="row" style={{ width: "80%" }}>
            <div
              className="col-lg-6 col-tight"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <img
                style={{ borderRadius: "12px", width: "80%" }}
                src={gif}
                alt="#IMPACT22"
              />
            </div>
            <div
              className="col-lg-5 offset-lg-1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                {/* <h2 className="site-header" style={{ color: "#808080" }}>
                  {" "}
                  #IMPACT2022
                </h2> */}
                <p
                  className="site-text"
                  style={{ color: "white", fontSize: "18px" }}
                >
                  We believe that 2022 is going to be a year where we make an
                  IMPACT in every aspect of our life.
                  <br />
                  Click below to watch more about this.
                </p>
                <button className="appLink" style={{ width: "200px" }}>
                  <a
                    href="https://www.youtube.com/watch?v=yw1TNYp4s2w"
                    target="_blank"
                    rel="noreferrer"
                  >
                    #IMPACT22
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="sub" id="activities">
          <h2 className="site-header" style={{ color: "white" }}>
            WHAT'S ON
          </h2>
          <Grid className="col-row" xs={8} container fluid>
            <Box className="item">
              <a
                href="https://www.youtube.com/fountainchurchkent"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img10} alt="sunday" />
              </a>
            </Box>
            <Box className="item">
              <a
                href="https://www.youtube.com/fountainchurchkent"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img11} alt="sunday" />
              </a>
            </Box>
            <Box className="item">
              <a
                href="https://www.youtube.com/fountainchurchkent"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img1} alt="sunday" />
              </a>
            </Box>
            <Box
              className="item"
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={this.handlePopoverOpen}
              onMouseLeave={this.handlePopoverClose}
            >
              <img className="item-img" src={img2} alt="bible" />
            </Box>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={this.handlePopoverClose}
              disableRestoreFocus
            >
              <p style={{ margin: "10px" }}>
                Please download our app to join our Bible Study.
              </p>
            </Popover>
            <Box className="item">
              <a
                href="https://www.facebook.com/fountainchurchkent/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img4} alt="morning" />
              </a>
            </Box>
            <Box className="item">
              <a
                href="https://www.instagram.com/fountain_outreach/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img8} alt="outreach" />
              </a>
            </Box>
            <Box
              className="item"
              aria-owns={newOpen ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={this.handleNewPopoverOpen}
              onMouseLeave={this.handleNewPopoverClose}
            >
              <img className="item-img" src={img3} alt="restore" />
            </Box>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={newOpen}
              anchorEl={this.state.anchorElNew}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={this.handleNewPopoverClose}
              disableRestoreFocus
            >
              <p style={{ margin: "10px" }}>This ministry is coming soon.</p>
            </Popover>
            <Box className="item">
              <a
                href="https://www.instagram.com/thebridge_fellowship/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img7} alt="theBridge" />
              </a>
            </Box>
            <Box className="item">
              <a
                href="https://www.instagram.com/bellesladies/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img6} alt="Belles" />
              </a>
            </Box>
            <Box className="item">
              <a
                href="https://www.instagram.com/cxnnected_/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img5} alt="CXNNECTED" />
              </a>
            </Box>
            <Box className="item">
              <a
                href="https://www.youtube.com/watch?v=_c38MMXVrUM&list=PLeNI85sOHZ-hqpXnHbWprYaepekL6gGj7"
                target="_blank"
                rel="noreferrer"
              >
                <img className="item-img" src={img9} alt="victorious" />
              </a>
            </Box>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Activities;
