import React, { Component } from "react";
import VK from "./watch/vK";
import vk from "../VK.jpeg";
import cc from "../CC.jpeg";

class ChildrensChurch extends Component {
  render() {
    return (
      <div className="cc container">
        <div className="content-cc1 overlay">
          <div className="layer">
            <div className="content"></div>
          </div>
        </div>
        <div className="content-cc2">
          <h1 className="site-header">Victorious Kids</h1>
          <p className="site-text">
            Children are the hope for tomorrow (future apostles, evangelists,
            pastors, politicians, engineers, teachers, researchers) etc...
            Mobilising our energy so that children discover Jesus at an early
            age is one of the main priorities at Fountain Church.
          </p>
          <h2>
            "
            <span className="verse">
              Train a child in the way he should go and when he is old he will
              not depart from it
            </span>
            " <br></br>Proverbs 22: 6
          </h2>
        </div>
        <VK />

        <div className="posters">
          <img src={vk} alt="Victorious Kids" />
          <img src={cc} alt="Children's church" />
        </div>
        <div className="content-cc3">
          <p className="site-text">
            We want to lay a strong foundation for our children. We want them to
            understand who Jesus is, the purpose of His coming on earth, the
            meaning of being His follower and the difference He can make in
            their lives, the habit of reading the Word of God and personal
            prayers, baptism in water and in the Holy Spirit, the Church and the
            Holy Communion, Bible stories and memory verses and many more
            christian issues and values.
          </p>
          <p className="site-text">
            We also teach them about the importance of witnessing about Christ
            to their peers and winning them to Christ.We make it plain to
            children that their peers will not be won to Christ by their word of
            testimony alone but also by the fruit of their lives (being good
            examples to their peers). Actions speak louder than words.
          </p>
        </div>
      </div>
    );
  }
}

export default ChildrensChurch;
