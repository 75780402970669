import React from "react";
import axios from "axios";
import { apiUrl } from "../config.json";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Recaptcha from "react-recaptcha";
import Form from "./common/form";
import CustomPhoneNumber from "./common/Number";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Icon,
  FormControl,
} from "@mui/material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import PhoneInput from "react-phone-number-input";

const apiEndpoint = apiUrl + "/NewVisitor";

class Visitor extends Form {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.change = this.change.bind(this);
    // this.changeName = this.changeName.bind(this);
    // this.changeEmail = this.changeEmail.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.state = {
      show: false,
      isSubmitted: false,
      isVerified: false,
      auth: "",
      data: {
        fullName: "",
        emailAddress: "",
        phoneNumber: "",
        referral: "",
      },
      errors: {},
    };
  }

  schema = {
    _id: Joi.string(),
    fullName: Joi.string().required().min(5).max(50).label("Name"),
    emailAddress: Joi.string().required().email().label("Email"),
    phoneNumber: Joi.string().required().label("Phone Number"),
    referral: Joi.string()
      .required()
      .min(4)
      .max(20)
      .label("How did you hear about us?"),
  };

  // doSomething(value) {
  //     const headers = { Authorization: this.state.referral };
  //     axios
  //       .post(apiEndpoint, { ...this.state.data }, { headers })
  //       .then(alert("Thank you for visiting Fountain Church"));
  //   }

  doSubmit() {
    if (this.state.isVerified) {
      const headers = { Authorization: this.state.auth };
      axios
        .post(apiEndpoint, { ...this.state.data }, { headers })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Thank you for visiting Fountain Church", {
              position: toast.POSITION.TOP_CENTER,
            });
            this.props.history.push("/come-again");
          }
        })
        .catch((response) => {
          if (response.status !== 200) {
            toast.error("There was an error", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    } else {
      toast.error("Please confirm that you are a human", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  recaptchaLoaded() {
    console.log("captcha successfully loaded");
  }

  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
      });
      this.setState({ auth: response });
    }
  }

  //   handleChange = ({ currentTarget: input }) => {
  //     const errors = { ...this.state.errors };
  //     // const errorMessage = this.validateProperty(input);
  //     // if (errorMessage) errors[input.name] = errorMessage;
  //     // else delete errors[input.name];

  //     const data = { ...this.state. };
  //     data[input.name] = input.value;

  //     this.setState({ data, errors });
  //   };

  handleShow() {
    if (this.state.show === true) {
      this.setState({ show: false });
    } else {
      this.setState({ show: true });
    }
  }

  change(event) {
    this.setState({
      data: {
        ...this.state.data,
        referral: event.target.value,
      },
    });
  }

  //   changeName(event) {
  //     // event.preventDefault();
  //     // event.stopPropagation();
  //     this.setState({
  //       fullName: event.target.value,
  //     });
  //   }
  //   changeEmail(event) {
  //     // event.preventDefault();
  //     // event.stopPropagation();
  //     this.setState({
  //       emailAddress: event.target.value,
  //     });
  //   }

  //   handleChange(e) {
  //     this.setState({
  //       data: {
  //         referral: e.target.value,
  //       },
  //     });
  //     console.log(this.state.data.referral);
  //   }
  //submit to api
  // doSubmit = async () => {
  //   await saveMember(this.state.data);
  //   let user = auth.getCurrentUser();

  //   if (user && (user = user.isAdmin)) this.props.history.push("/members");
  //   else this.props.history.replace("/");
  // };

  render() {
    return (
      <div
        className="visit"
        style={{
          // display: "flex",
          // justifyContent: "center",
          marginTop: "5%",
          marginBottom: "5%",
          zIndex: 1,
        }}
      >
        <div className="wash"></div>
        <Grid
          style={{
            zIndex: 1,
          }}
        >
          <Card
            className="visit-form"
            style={{
              zIndex: 1,
              position: "relative",
              maxWidth: 450,
              padding: "20px 5px",
              margin: "0 auto",
              boxShadow: "0 0 5px #175873",
            }}
          >
            <CardContent>
              <Typography variant="h2" className="site-header">
                Thank you for visiting us!
              </Typography>
              <Typography
                variant="h5"
                color="textSecondary"
                className="site-text"
                component="p"
                style={{ marginBottom: "15px" }}
              >
                Please share your name and how you first heard about Fountain
                Church.
              </Typography>
              <form onSubmit={this.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid xs={12} item>
                    {this.renderInput("fullName", "Full Name")}
                  </Grid>
                  <Grid xs={12} item style={{ marginTop: "10px" }}>
                    {this.renderInput("emailAddress", "Email")}
                  </Grid>

                  <Grid xs={12} item style={{ marginTop: "10px" }}>
                    {this.state.show === false && (
                      <FormControl fullWidth>
                        <InputLabel id="select-label">
                          How did you hear about us?
                        </InputLabel>
                        <Select
                          name="referral"
                          labelId="select-label"
                          id="select"
                          value={this.state.data.referral}
                          required
                          fullWidth
                          label="How did you hear about us?"
                          onChange={this.change}
                        >
                          <MenuItem value="FaceBook">FaceBook</MenuItem>
                          <MenuItem value="YouTube">YouTube</MenuItem>
                          <MenuItem value="Instagram">Instagram</MenuItem>
                          <MenuItem value="Other" onClick={this.handleShow}>
                            Other
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {this.state.show === true && (
                      <div>
                        <InputLabel id="select-label">
                          How did you hear about us?
                        </InputLabel>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            name="referral"
                            label="Other"
                            onChange={this.change}
                            fullWidth
                            required
                          />
                          <Icon
                            component={SettingsBackupRestoreIcon}
                            onClick={this.handleShow}
                            style={{ color: "#175873", marginLeft: "5px" }}
                          />
                        </div>
                      </div>
                    )}
                    <PhoneInput
                      placeholder="Enter phone number"
                      defaultCountry={"GB"}
                      name="phoneNumber"
                      value={this.state.data.phoneNumber}
                      onChange={(event) => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            phoneNumber: event,
                          },
                        });
                      }}
                      inputComponent={CustomPhoneNumber}
                    />
                  </Grid>
                  <Grid xs={12} item></Grid>
                  <Grid xs={12} item style={{ marginTop: "30px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn1"
                      fullWidth
                    >
                      Submit
                    </Button>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <Recaptcha
                        sitekey="6LcUFwodAAAAAEZfS5CHHZ9VMK4XIhAotNNM5uge"
                        render="explicit"
                        onloadCallback={this.recaptchaLoaded}
                        verifyCallback={this.verifyCallback}
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default Visitor;
